
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <h4 class="display-1 mb-3 d-inline">{{ culturePulse.name }}</h4>
        <v-chip disabled
          v-if="culturePulse.displayName"
          class="mb-4 ml-2 white--text right"
          color="primary"
        >
          {{ culturePulse.displayName }}
        </v-chip>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title> {{ $t('culturePulses.summary') }} </v-toolbar-title>
          </v-toolbar>
          <v-row>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ $t('culturePulses.total_progress') }}: {{ progress }}</h6>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text subheading">
                        ({{ completed }} {{ $t('culturePulses.poll_completed') }})
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>mdi-alert-circle-outline</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ $t('requiredCulture.status') }}</h6>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text body-1">
                        {{ getStatus() }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="offset-6">
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>calendar_today</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <h6 class="title">{{ $t('requiredCulture.period_validity') }}</h6>
                      </v-list-item-title>
                      <v-list-item-subtitle class="grey--text subheading">
                        {{ culturePulse.releaseAt }}
                        {{$t('requiredCulture.to_the')}}
                        {{ culturePulse.deadlineAt }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="mt-5">
                  <v-btn
                    to="/culture-pulses/list"
                    large
                    block
                  >{{ $t('input.back') }}</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-overflow-btn
                        v-model="selectedAction"
                        :items="actions"
                        :label="$t('requiredCulture.action')"
                        item-value="value"
                        append-icon="mdi-settings"
                        class="my-2"
                        @change="executeAction"
                      >
                        <template v-slot:item="prop">
                          <span>
                            <v-icon medium :color="prop.item.color">{{prop.item.icon}}</v-icon>
                            {{ prop.item.text }}
                          </span>
                        </template>
                      </v-overflow-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-container>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="associateRequiredCulture" width="760px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline text-center">{{ $t('culturePulses.associate_required_culture_title') }}</span>
        </v-card-title>
        <v-card-subtitle>
          <p class="mb-0 pt-5">{{ $t('culturePulses.associate_required_culture_desc') }}</p>
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="center" class="mt-10">
            <v-col>
              <v-overflow-btn
                v-model="culturePulse.requiredCultureId"
                class="my-2"
                :items="requiredCultures"
                :label="$t('culturePulses.associate_required_culture_select')"
                item-value="value"
                :clearable="true"
              >
                <template v-slot:item="prop">
                  <span>
                    {{ prop.item.text }}
                  </span>
                </template>
              </v-overflow-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row class="ma-0 pa-2">
            <v-col cols="6" cla>
              <v-btn block large @click="associateRequiredCulture = false; selectedRequiredCulture = ''">
                {{ $t('input.close') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block large
                color="primary"
                :disabled="!culturePulse.requiredCultureId"
                @click="saveAssociate"
              >
                {{ $t('input.associate') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <x-confirmation-modal
      :show.sync="closingPoll"
      :reversible="true"
      :title="$t('input.poll_delete_title')"
      :action="closePoll"
      :btn-save="$t('input.close_poll')"
      @close="closingPoll = false"
    >
      <template v-slot:question>{{ $t('input.master_references_delete_poll') }}</template>
    </x-confirmation-modal>
    <x-confirmation-modal
      :show.sync="reminderPoll"
      :reversible="true"
      :title="$t('culturePulses.reminders')"
      :action="resend"
      :btn-save="$t('culturePulses.reminders')"
      @close="reminderPoll = false"
    >
      <template v-slot:question>{{ $t('culturePulses.reminders_info') }}</template>
    </x-confirmation-modal>

    <x-json-modal
      :showDialog="showJsonModal"
      :questionnaire="culturePulse.questionnaire"
      @close="showJsonModal = false"
    >
    </x-json-modal>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import XJsonModal from '../../components/dialogs/spreadsheet-json-pulse'

import culturePulseService from '../../services/culture-pulse'
import RequiredCultureService from '../../services/required-culture'

const formatItems = items => {
  return items.map((item) => {
    return {
      value: item.id,
      text: item.name
    }
  })
}

export default {
  components: {
    XJsonModal
  },
  data () {
    return {
      showJsonModal: false,
      culturePulse: {
        id: '',
        status: '',
        name: '',
        releaseAt: '',
        deadlineAt: '',
        requiredCultureId: ''
      },
      completed: '',
      progress: '',
      closingPoll: false,
      reminderPoll: false,
      associateRequiredCulture: false,
      requiredCultures: [],
      selectedAction: '',
      actions: [
        { text: this.$t('culturePulses.associate_required_culture'), value: 'Associate', icon: 'mdi-arrange-bring-to-front', color: '' },
        { text: this.$t('input.edit'), value: 'Edit', icon: 'mdi-square-edit-outline', color: '' },
        { text: this.$t('reports.view_report'), value: 'PDF', icon: 'mdi-chart-bar-stacked', color: '' },
        { text: this.$t('culturePulses.resend'), value: 'Resend', icon: 'mdi-av-timer', color: '' },
        { text: this.$t('followupReport.follow_up'), value: 'FollowUp', icon: 'mdi-eye', color: '' },
        { text: this.$t('input.close_poll'), value: 'Close', icon: 'mdi-lock', color: '' }
      ]
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.getCulturePulse()
      }
    },
    showJsonModal (val) {
      if (!val) {
        this.selectedAction = ''
      }
    }
  },
  methods: {
    getStatus () {
      if (this.culturePulse.status === 'pending') {
        return this.$t('requiredCulture.pending')
      } else if (this.culturePulse.status === 'in_progress') {
        return this.$t('requiredCulture.progress')
      }
      return this.$t('requiredCulture.completed')
    },
    executeAction (action) {
      switch (action) {
        case 'Associate':
          return this.culturePulse.status === 'completed'
            ? this.openAssociateModal() : ''
        case 'FollowUp':
          return this.culturePulse.status === 'in_progress'
            ? this.$router.push(`/culture-pulse/${this.culturePulse.id}/followup`) : ''
        case 'Edit':
          return this.culturePulse.status === 'pending' || this.culturePulse.status === 'in_progress'
            ? this.$router.push(`/culture-pulse/${this.culturePulse.id}/edit`) : ''
        case 'PDF':
          return this.culturePulse.status === 'completed'
            ? this.$router.push(`/culture-pulse/${this.culturePulse.id}/report`) : ''
        case 'XLS':
          this.showJsonModal = true
          break
        case 'Resend':
          if (this.culturePulse.status === 'in_progress') {
            this.reminderPoll = true
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.evaluation_resend_${this.culturePulse.status}`))
          }
          break
        case 'Close':
          return (this.closingPoll = this.culturePulse.status === 'in_progress')
      }
    },
    getPercent (population, answers) {
      population = population || 0
      answers = answers || 0
      this.completed = `${answers} de ${population}`
      this.progress = `${answers && population ? ((answers / population) * 100).toFixed(2) : 0}%`
    },
    getCulturePulse () {
      culturePulseService.getOneToShow(this.$route.params.id)
        .then((res) => this.reponseParse(res))
    },
    reponseParse (res) {
      this.culturePulse.id = res._id
      this.culturePulse.status = res.status
      this.culturePulse.name = res.name
      this.culturePulse.displayName = res.displayName
      this.culturePulse.requiredCultureId = res.requiredCultureId
      this.culturePulse.releaseAt = this.formatDate(this.getDate(res.releaseAt))
      this.culturePulse.deadlineAt = this.formatDate(this.getDate(res.deadlineAt))
      this.culturePulse.questionnaire = res.questionnaire
      this.filterActions(res.status)
      this.getPercent(res.population, res.answers)
    },
    filterActions (status) {
      const endActions = []
      for (let action of this.actions) {
        if (status === 'pending' && action.value === 'Edit') {
          action.color = 'blue'
          endActions.push(action)
        } else if (status === 'in_progress' && (action.value === 'Edit' || action.value === 'Resend' || action.value === 'Close' || action.value === 'FollowUp')) {
          action.color = 'blue'
          endActions.push(action)
        } else if (status === 'completed' && (action.value === 'PDF' || action.value === 'Associate')) {
          action.color = 'blue'
          endActions.push(action)
        }
      }
      this.actions = endActions

      // Only Super Admin. can download spreadsheet on completed assessments
      if (this.user.origin && status === 'completed') {
        this.actions.unshift({ text: 'Sábana de Datos', value: 'XLS', icon: 'mdi-file-excel', color: 'success' })
      }
    },
    openAssociateModal () {
      this.associateRequiredCulture = true
      this.getRequiredCultures()
    },
    saveAssociate () {
      return culturePulseService.associate(this.culturePulse.id, this.culturePulse.requiredCultureId).then(res => {
        this.associateRequiredCulture = false
        if (res._id) {
          this.$store.dispatch('alert/success', this.$t('culturePulses.associate_saved'))
        }
      }).catch((err) => {
        this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
      })
    },
    getRequiredCultures () {
      this.requiredCultures = []
      return RequiredCultureService.listCompleted().then(res => {
        res.forEach(element => {
          this.requiredCultures.push({ text: element.name, value: element._id })
        })
      })
    },
    formatDate (data) {
      const [year, month, day] = data.value.split('-')
      return `${day}/${month}/${year} ` + this.$t('culturePulses.at').toLowerCase() + ` ${data.hour}:${data.minut}`
    },
    getDate (ref) {
      if (!ref) {
        return {
          value: '',
          hour: 23,
          minut: 59
        }
      }
      const date = ref.split('T')
      const time = date[1].split(':')
      return {
        value: date[0],
        hour: time[0],
        minut: time[1]
      }
    },
    resend () {
      return culturePulseService.resend(this.$route.params.id)
        .then((res) => this.$store.dispatch('alert/success', this.$t('culturePulses.resend_success')))
        .catch((err) => this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`)))
    },
    closePoll () {
      return culturePulseService.close(this.$route.params.id)
        .then((res) => {
          this.$store.dispatch('alert/success', this.$t('culturePulses.closed_poll'))
          this.$router.push('/culture-pulses/list')
        }).catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    }
  },
  created () {
    this.getCulturePulse()
  }
}
</script>

<style lang="css" scoped>
  .justify-h {
    margin-top: 1em
  }
  .v-list-item__subtitle, .v-list-item__title {
    white-space: normal;
  }
</style>
