
<template>
  <v-stepper-content :step="step">
    <v-col cols="12" class="headline">
      {{ $t('culturePulses.questions') }}
    </v-col>
    <v-divider></v-divider>

    <v-col cols="12">
      <v-switch v-if="!isEdit"
        v-model="culturePulse.switchCurrentCultureQuestionnaire"
        :readonly="culturePulse.status !== 'pending'"
        :label="$t('culturePulses.want_current_culture_questions')"
        color="primary"
        :append-icon="$t('help.icon')"
        @click:append="$store.dispatch('help/display', $t('help.pulse.create.currentCultureQuestions'))"
      ></v-switch>
      <x-inputs-autocomplete light
        v-if="culturePulse.switchCurrentCultureQuestionnaire"
        name="current_culture"
        rules="required"
        :class="{
          'ml-4': true,
          'my-12': !relatedCurrentCultureQuestionnaire
        }"
        :items="currentCultures"
        :input="relatedCurrentCultureQuestionnaire"
        :label="$t('culturePulses.current_culture')"
        :loading="loadingCurrentCultures"
        :disabled="loadingCurrentCultures"
        :append-outer-icon="$t('help.icon')"
        :help-message="$t('help.pulse.create.selection')"
        @updateInput="($event) => relatedCurrentCultureQuestionnaire = $event"
      ></x-inputs-autocomplete>
    </v-col>

    <v-row
      v-show="!culturePulse.switchCurrentCultureQuestionnaire || (culturePulse.switchCurrentCultureQuestionnaire && relatedCurrentCultureQuestionnaire)"
      v-for="(item, index) in availableQuestionnaires"
      v-bind:key="index"
    >
      <v-col cols="12" sm="8" max-height="20px" class="pt-0 pb-0 pr-0 pl-9">
        <v-radio-group
          v-model="culturePulse.questionnaire"
          :value="culturePulse.questionnaire"
          :disabled="['in_progress','completed'].includes(culturePulse.status)"
          :readonly="isEdit"
        >
          <v-radio
            color="primary"
            :label="item.name"
            :value="item.slug"
          ></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" sm="4" class="pt-4 pb-0 pr-6 pl-0 text-right">
        <v-btn dark small
          color="primary"
          @click="getPdfAsync(item)"
        >
          {{ $t('requiredCulture.download') }} {{ $t('requiredCulture.questionnaire') }}
          <v-icon dark right small>mdi-file-pdf</v-icon>
        </v-btn>
      </v-col>

      <!------------------------ Dimensions Selection Table ------------------------>
      <v-col cols="12"
        v-if="culturePulse.questionnaire && culturePulse.questionnaire === item.slug"
        class="pt-0 pl-10 pr-7"
      >
        <p class="mb-0 body-2 text-right font-weight-bold">
          {{ $t('culturePulses.selected') }} {{ selectedDimensionCount }}
        </p>
        <v-simple-table dense
          fixed-header
          height="74vh"
          class="questions-table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left bordered-header" width="130px">
                  {{ $t('requiredReport.culture_types') }}
                </th>
                <th class="text-left pl-11" width="100px">
                  {{ $t('requiredReport.dimension') }}
                </th>
                <th class="text-center">
                  {{ $t('culturePulses.questions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(dimensions, type) in item.cultureTypes">
                <tr
                  v-for="(dimension, i) in dimensions"
                  :key="dimension.name"
                >
                  <td v-if="i === 0" rowspan="3" class="pl-5"
                    :class="{'no-border': type === 'competitive'}"
                  >
                    {{ $t(`evaluations.${type}`) }}
                  </td>
                  <td class="pr-0 pl-3">
                    {{ $t(`evaluations.${dimension.name}`) }}
                  </td>
                  <td class="text-left spaced-cell">
                    <v-checkbox dense hide-details
                      v-for="question in dimension.questions" :key="`${dimension.name}-${question.name}`"
                      v-model="culturePulse.selectedDimensions[dimension.name][question.name].selected"
                      :ripple="false"
                      :readonly="culturePulse.status !== 'pending' || isEdit"
                      :disabled="
                        selectedDimensionCount >= maxAttributes &&
                        !culturePulse.selectedDimensions[dimension.name][question.name].selected
                      "
                      :label="question.positive[$i18n.locale]"
                      color="primary"
                      class="mt-0 pt-0 small-label"
                      style="width: fit-content;"
                      @click="selectedDimensionsCounter()"
                    ></v-checkbox>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mb-0 ml-1 caption primary--text font-weight-bold">
          {{ $t('culturePulses.questionnaire_table_legend', { min: minAttributes, max: maxAttributes }) }}
        </p>
      </v-col>
    </v-row>

    <!------------------------- Additional Questions ------------------------->
    <v-col cols="12" v-if="!culturePulse.switchCurrentCultureQuestionnaire || (culturePulse.switchCurrentCultureQuestionnaire && relatedCurrentCultureQuestionnaire)">
      <v-switch
        :readonly="culturePulse.status !== 'pending' || isEdit"
        v-model="culturePulse.switchAdditionalQuestion"
        :label="$t('culturePulses.want_open_question')"
        color="primary"
        :append-icon="$t('help.icon')"
        @click:append="$store.dispatch('help/display', $t('help.pulse.create.open'))"
      ></v-switch>
      <x-info v-if="culturePulse.switchAdditionalQuestion"
        :text="$t('culturePulses.open_question_info')"
      ></x-info>
    </v-col>

    <ValidationObserver ref="additional_qs" v-slot="{ validate, reset }">
    <v-row no-gutters v-if="culturePulse.switchAdditionalQuestion">
      <v-col cols="12" class="headline px-4">
        {{ $t('culturePulses.open_question') }}
        <v-tooltip :disabled="culturePulse.additionalQuestions.length === maxOpenQuestion || culturePulse.status !== 'pending'" bottom color="green lighten-3">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab small
              color="#068ad5"
              class="white--text ml-4"
              :disabled="culturePulse.additionalQuestions.length === maxOpenQuestion || culturePulse.status !== 'pending' || computedMoreAdditionalQs"
              @click="addOpenQuestion"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('culturePulses.add_open_question') }}</span>
        </v-tooltip>
        <v-tooltip :disabled="culturePulse.additionalQuestions.length === minOpenQuestion || culturePulse.status !== 'pending'" bottom color="red">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              fab small
              color="#f65871"
              class="white--text ml-4"
              :disabled="culturePulse.additionalQuestions.length === minOpenQuestion || culturePulse.status !== 'pending'"
              @click="removeOpenQuestion"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('culturePulses.remove_open_question') }}</span>
        </v-tooltip>
        <v-divider class="mt-1"></v-divider>
      </v-col>

      <!-- Questions -->
      <v-col cols="12" sm="6"
        v-for="(additional, idx) in culturePulse.additionalQuestions"
        :key="`q${idx}`"
        class="px-5 pb-6"
      >
        <v-row>
          <v-col cols="12">
            <ValidationProvider v-slot="{ errors }"
              :name="$t('culturePulses.additional_n', {n: idx + 1})"
              :rules="{
                required: hasOptions(additional)
              }"
              mode="lazy"
            >
              <v-text-field light
                v-model="additional.question"
                :label="$t('culturePulses.insert_question', {n: idx + 1})"
                :name="`external_name${idx}`"
                :readonly="culturePulse.status !== 'pending'"
                :disabled="culturePulse.status !== 'pending'"
                :error-messages="errors[0]"
              >
                <template #append>
                  <v-tooltip :disabled="additional.options.length === maxOptionOpenQuestion || culturePulse.status !== 'pending'" bottom color="green lighten-3">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        fab small
                        color="#068ad5"
                        class="white--text"
                        :disabled="additional.options.length === maxOptionOpenQuestion || culturePulse.status !== 'pending'"
                        @click="addOptionOpenQuestion(additional)"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('culturePulses.add_question_option') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <!-- Options -->
          <v-col cols="12" xs="11" offset-xs="1" v-for="(option, $i) in additional.options" :key="$i">
            <ValidationProvider v-slot="{ errors }"
              :name="$t('culturePulses.option_n', {n: $i + 1})"
              :rules="{
                required: additional.question !== ''
              }"
              mode="eager"
            >
              <v-text-field light
                v-model="additional.options[$i]"
                :label="$t('culturePulses.insert_question_option', {n: idx + 1, o: $i + 1})"
                :name="`external_name${idx}${$i}`"
                :readonly="culturePulse.status !== 'pending'"
                :disabled="culturePulse.status !== 'pending'"
                :error-messages="errors[0]"
              >
                <template v-slot:append>
                  <v-tooltip :disabled="additional.options.length === minOptionOpenQuestion || culturePulse.status !== 'pending'" bottom color="red">
                    <template v-slot:activator="{ on }">
                      <v-btn fab small
                        v-on="on"
                        color="#f65871"
                        class="white--text"
                        :disabled="additional.options.length === minOptionOpenQuestion || culturePulse.status !== 'pending'"
                        @click="removeOptionOpenQuestion(additional, $i)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('culturePulses.remove_question_option') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    </ValidationObserver>

    <!---------------------------- Action Buttons ---------------------------->
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn block large
          @click="changeStep(+step - 1)"
        >
          {{ $t('input.back') }}
        </v-btn>
      </v-col>

      <v-col cols="12" sm="6">
        <v-btn block large
          color="primary"
          :disabled="culturePulse.switchCurrentCultureQuestionnaire && !relatedCurrentCultureQuestionnaire"
          @click="checkNext()"
        >
          {{ $t('input.next') }}
        </v-btn>
      </v-col>
    </v-row>

    <!------------------------ No Questonnaire Dialog ------------------------>
    <v-dialog v-model="showDialog" width="600px" :persistent="true">
      <v-card>
        <v-card-title>
          <span class="headline"> {{ $t('culturePulses.no_questionnaries_title') }} </span>
        </v-card-title>
        <v-card-text>
          {{ $t('culturePulses.no_questionnaries_body') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showDialog = !showDialog"
          >
            {{ $t('input.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <img
      src="/img/20200302_occ_cultura_logo.png"
      style="visibility:hidden;"
      id="occCultureLogo"
      width="0"
      height="0"
      alt=""
    />
  </v-stepper-content>
</template>

<style lang="scss" scoped>
  ::v-deep .small-label .v-label {
    font-size: 12px !important;
    color: #222222;
  }
  .questions-table {
    border-radius: 4px 0 0 4px;
    border: 1px solid #CCCCCC;
    tbody {
      tr:hover {
        cursor: default;
        background-color: transparent !important;
      }
    }
  }
  ::v-deep .questions-table .bordered-header {
    border-radius: 4px 0 0 0;
  }
  ::v-deep .questions-table .no-border {
    border-bottom: none !important;
  }
  ::v-deep .questions-table .spaced-cell {
    padding-top: 6px;
    padding-bottom: 4px;
  }
</style>

<script>

import { mapState } from 'vuex'
import is from 'is_js'

import questionnairesService from '../../../services/questionnaires'
import CurrentCultureAssessmentService from '../../../services/current-culture-assessment'

const pdfmake = require('pdfmake/build/pdfmake')
const pdffonts = require('pdfmake/build/vfs_fonts.js')
pdfmake.vfs = pdffonts.pdfMake.vfs

export default {
  props: {
    culturePulse: Object,
    step: String,
    isEdit: Boolean,
    nextAction: String,
    prevAction: String
  },
  data () {
    return {
      minAttributes: 1,
      maxAttributes: 24,
      loadingCurrentCultures: false,
      availableQuestionnaires: [],
      relatedCurrentCultureQuestionnaire: null,
      currentCultures: [],
      cultureLogoSrc: null,
      cultureLogoBase64: null,
      selectedDimensionCount: 0,
      minOpenQuestion: 1,
      maxOpenQuestion: 3,
      minOptionOpenQuestion: 2,
      maxOptionOpenQuestion: 5,
      showDialog: false,
      loading: false
    }
  },
  watch: {
    loading (val) {
      if (val) {
        this.$store.dispatch('loading/show')
      } else {
        this.$store.dispatch('loading/hide')
      }
    },
    cultureLogoSrc (newVal, oldVal) {
      if (newVal) {
        this.toDataURL(this.cultureLogoSrc, (dataURL) => {
          this.cultureLogoBase64 = dataURL
        })
      }
    },
    relatedCurrentCultureQuestionnaire (val) {
      this.culturePulse.questionnaire = ''
      const cloneQuestionnaires = JSON.parse(JSON.stringify(this.culturePulse.questionnaires))
      if (val) {
        this.availableQuestionnaires = cloneQuestionnaires.filter(q => q.slug === val)
      } else {
        this.availableQuestionnaires = cloneQuestionnaires
      }
    },
    'culturePulse.switchCurrentCultureQuestionnaire': {
      handler (val) {
        if (val) {
          if (!this.currentCultures.length) {
            this.getCurrentCultures()
          }
        } else {
          this.relatedCurrentCultureQuestionnaire = null
        }
      }
    },
    'culturePulse.questionnaire': {
      handler (val) {
        if (val) {
          if (!this.isEdit) {
            const q = this.availableQuestionnaires.find(q => q.slug === val)
            for (const key of Object.keys(q.cultureTypes)) {
              q.cultureTypes[key].forEach(d => {
                if (!this.culturePulse.selectedDimensions[d.name]) {
                  this.culturePulse.selectedDimensions[d.name] = {}
                }
                d.questions.forEach(q => {
                  this.culturePulse.selectedDimensions[d.name][q.name] = { selected: false }
                })
              })
            }
          } else {
            this.selectedDimensionsCounter()
          }
        } else {
          this.culturePulse.selectedDimensions = {}
        }
        this.selectedDimensionCount = 0
      }
    },
    'culturePulse.switchAdditionalQuestion': {
      handler (val) {
        if (!val) {
          this.culturePulse.additionalQuestions = [{
            question: '',
            options: ['', '']
          }]
        }
      }
    }
  },
  computed: {
    computedMoreAdditionalQs () {
      const lngth = this.culturePulse.additionalQuestions.length
      const lastQ = this.culturePulse.additionalQuestions[lngth - 1]
      const emptyOpts = lastQ.options.reduce((acc, opt) => {
        if (opt === '') {
          acc++
        }
        return acc
      }, 0)
      if (lastQ.question === '' || emptyOpts) {
        return true
      }
      return false
    },
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    changeStep (step) {
      this.$emit('changeStep', this.culturePulse, step)
    },
    hasOptions (additional) {
      const optWithValue = additional.options.find(opt => opt !== '')
      if (optWithValue) {
        return true
      }
      return false
    },
    checkNext () {
      if (this.culturePulse.switchAdditionalQuestion) {
        this.$refs.additional_qs.validate()
          .then(valid => {
            if (valid) {
              this.next()
            }
          })
      } else {
        this.next()
      }
    },
    next () {
      if (!this.culturePulse.questionnaire) {
        this.$store.dispatch('alert/error', this.$t('errors.error_select_questionnaires'))
      } else if (!this.selectedDimensionCount) {
        this.$store.dispatch('alert/error', this.$t('errors.error_select_pulse_dimensions'))
      } else {
        this.changeStep(+this.step + 1)
      }
    },
    getCurrentCultures () {
      this.loadingCurrentCultures = true
      CurrentCultureAssessmentService.listCompletedByEnterpriseWithQuestionnaire()
        .then((res) => {
          this.currentCultures = res.items.map(x => {
            return {
              text: x.displayName ? `${x.displayName} (${x.name})` : x.name,
              value: x.questionnaire.slug
            }
          })
        })
        .finally(() => {
          this.loadingCurrentCultures = false
        })
    },
    selectedDimensionsCounter () {
      let cnt = 0
      setTimeout(() => {
        for (const dimKey of Object.keys(this.culturePulse.selectedDimensions)) {
          for (const attKey of Object.keys(this.culturePulse.selectedDimensions[dimKey])) {
            if (this.culturePulse.selectedDimensions[dimKey][attKey].selected) {
              cnt++
            }
          }
        }
        this.selectedDimensionCount = cnt
      }, 140)
    },
    removeOpenQuestion () {
      this.culturePulse.additionalQuestions.pop()
    },
    addOpenQuestion () {
      this.culturePulse.additionalQuestions.push({
        question: '',
        options: ['', '']
      })
    },
    removeOptionOpenQuestion (open, idx) {
      open.options.splice(idx, 1)
    },
    addOptionOpenQuestion (open) {
      open.options.push('')
    },
    toDataURL (url, callback) {
      const xhr = new XMLHttpRequest()
      xhr.open('get', url)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const fr = new FileReader()

        fr.onload = function () {
          callback(this.result)
        }

        fr.readAsDataURL(xhr.response)
      }

      xhr.send()
    },
    writeRotatedText (text) {
      const canvas = document.createElement('canvas')
      canvas.width = 50
      canvas.height = 845

      const ctx = canvas.getContext('2d')

      // Genera color de fondo
      ctx.fillStyle = '#2196F3'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.save()

      // Posiciona el elemento al costado derecho de la página
      ctx.translate(50, 845)
      ctx.rotate(-0.5 * Math.PI)

      // Formatea el texto
      ctx.font = '20pt Roboto'
      ctx.fillStyle = 'white'
      ctx.fillText(text.toUpperCase(), 290, -15)
      ctx.restore()

      return canvas.toDataURL()
    },
    getPdfAsync (questionnaire) {
      this.loading = true
      new Promise((resolve, reject) => {
        setTimeout(() => { resolve() }, 10)
      }).then(() => {
        this.getPdf(questionnaire)
      })
    },
    getPdf (questionnaire) {
      let details = []
      let i = 0
      details.push({ text: this.$t('cultureReports.responsible').toUpperCase(), bold: true, fontSize: 15, margin: [15, 0, 0, 0] })
      for (const attr of questionnaire.cultureTypes.responsible) {
        let attrObj = { text: this.$t('cultureReports.' + attr.name).toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0], colSpan: 2, alignment: 'center' }
        const arrBody = [[
          attrObj, ''
        ]]
        arrBody.push([
          { text: this.$t('cultureReports.positive').toUpperCase(), alignment: 'center' },
          { text: this.$t('cultureReports.limiting').toUpperCase(), alignment: 'center' }
        ])

        attr.questions.forEach((question) => {
          arrBody.push([
            { text: question.positive[this.user.lang], fontSize: 10 },
            { text: question.limiting[this.user.lang], fontSize: 10 }
          ])
        })
        details.push({
          table: {
            body: arrBody,
            widths: [230, 230]
          },
          margin: [0, 0, 0, 10],
          pageBreak: i === 1 ? 'after' : ''
        })
        i++
      }
      details.push({ text: this.$t('cultureReports.competitive').toUpperCase(), bold: true, fontSize: 15, margin: [15, 5, 0, 0] })
      for (const attr of questionnaire.cultureTypes.competitive) {
        let attrObj = { text: this.$t('cultureReports.' + attr.name).toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0], colSpan: 2, alignment: 'center' }
        const arrBody = [[
          attrObj, ''
        ]]
        arrBody.push([
          { text: this.$t('cultureReports.positive').toUpperCase(), alignment: 'center' },
          { text: this.$t('cultureReports.limiting').toUpperCase(), alignment: 'center' }
        ])

        attr.questions.forEach((question) => {
          arrBody.push([
            { text: question.positive[this.user.lang], fontSize: 10 },
            { text: question.limiting[this.user.lang], fontSize: 10 }
          ])
        })
        details.push({
          table: {
            body: arrBody,
            widths: [230, 230]
          },
          margin: [0, 0, 0, 20],
          pageBreak: i === 3 || i === 5 ? 'after' : ''
        })
        i++
      }
      details.push({ text: this.$t('cultureReports.humanist').toUpperCase(), bold: true, fontSize: 15, margin: [15, 5, 0, 0] })
      for (const attr of questionnaire.cultureTypes.humanist) {
        let attrObj = { text: this.$t('cultureReports.' + attr.name).toUpperCase(), bold: true, fontSize: 13, margin: [15, 5, 0, 0], colSpan: 2, alignment: 'center' }
        const arrBody = [[
          attrObj, ''
        ]]
        arrBody.push([
          { text: this.$t('cultureReports.positive').toUpperCase(), alignment: 'center' },
          { text: this.$t('cultureReports.limiting').toUpperCase(), alignment: 'center' }
        ])

        attr.questions.forEach((question) => {
          arrBody.push([
            { text: question.positive[this.user.lang], fontSize: 10 },
            { text: question.limiting[this.user.lang], fontSize: 10 }
          ])
        })
        details.push({
          table: {
            body: arrBody,
            widths: [230, 230]
          },
          margin: [0, 0, 0, 20],
          pageBreak: i === 7 ? 'after' : ''
        })
        i++
      }
      const configuration = {
        pageSize: 'A4',
        info: {
          title: this.$t('cultureReports.title'),
          author: 'OCC',
          subject: this.$t('cultureReports.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: () => {
          return [{
            image: this.cultureLogoBase64,
            height: 30,
            width: 40,
            margin: [15, 15, 25, 15]
          }]
        },
        footer: () => {
          return [
            {
              columns: [
                { width: '*', text: '' },
                {
                  width: 'auto',
                  text: this.$t('cultureReports.copyright'),
                  color: 'grey',
                  fontSize: 10
                },
                { width: '*', text: '' }
              ]
            }
          ]
        },
        background: () => {
          const result = {
            image: this.writeRotatedText(questionnaire.name),
            aligment: 'center',
            absolutePosition: { x: 545, y: 0 }
          }

          return result
        },
        content: [
          // Título
          {
            text: this.$t('culturePulses.questionnaire_e'),
            fontSize: 20,
            margin: [0, 20, 0, 10]
          },
          // Explicación
          {
            text: this.$t('culturePulses.pdf_explained', { name: questionnaire.name }),
            alignment: 'justify',
            margin: [0, 0, 20, 10]
          },
          // Cuestionario
          ...details
        ]
      }
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${questionnaire.name}.pdf`)
          this.loading = false
        })
      } else {
        pdfmake.createPdf(configuration).download(`${questionnaire.name}.pdf`, () => { this.loading = false })
      }
    }
  },
  created () {
    questionnairesService.listFiltered()
      .then(res => {
        this.culturePulse.questionnaires = res.items
        this.availableQuestionnaires = JSON.parse(JSON.stringify(this.culturePulse.questionnaires))
        this.showDialog = !this.culturePulse.questionnaires.length
      })
  },
  mounted () {
    this.cultureLogoSrc = document.getElementById('occCultureLogo').src
  }

}
</script>
