import { render, staticRenderFns } from "./show.vue?vue&type=template&id=25074131&scoped=true"
import script from "./show.vue?vue&type=script&lang=js"
export * from "./show.vue?vue&type=script&lang=js"
import style0 from "./show.vue?vue&type=style&index=0&id=25074131&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25074131",
  null
  
)

export default component.exports