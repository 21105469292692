let withPie = false
const checkBreack = (countLines: number): boolean => {
  const AVAILABLELINESFORFIRSTPAGE = withPie ? 18 : 20
  const AVAILABLELINESFOROTHERPAGES = 22
  if (countLines > (AVAILABLELINESFORFIRSTPAGE + AVAILABLELINESFOROTHERPAGES)) {
    return checkBreack(countLines - AVAILABLELINESFOROTHERPAGES)
  }
  return AVAILABLELINESFORFIRSTPAGE / countLines === 1 || (AVAILABLELINESFORFIRSTPAGE + AVAILABLELINESFOROTHERPAGES) / countLines === 1
}

export default (items: Array<any>, vueObject: any, lang: string, pie = false) => {
  withPie = pie
  const tables = []
  let countLines = 1
  const margins = {
    m0: [0, 1, 0, 0],
    m1: [25, 1, 25, 0],
    m2: [5, 1, 5, 0],
    m3: [25, 1, 25, 0]
  }
  const basicRow = (text: string|number, color: string, alignment: string, margin: 'm0'|'m1'|'m2'|'m3', border: boolean) => ({
    text,
    color,
    margin: margins[margin],
    alignment,
    fontSize: 10,
    border: [false, border, false, false],
    borderColor: ['', border ? '#d1d1d1' : '', '', '']
  })

  const headerTables = (itemName: string, vueObject: any) => [
    basicRow(vueObject.$t(`reports.demographicCuts.${itemName}`).toUpperCase(), '#5c5655', 'left', 'm1', false),
    basicRow(vueObject.$t('cultureReports.total_obtained'), '#5c5655', 'center', 'm0', false),
    basicRow(vueObject.$t('cultureReports.total_sent'), '#5c5655', 'center', 'm0', false)
  ]
  const footerTables = (totalPerRates: number, totalPerPopulation: number, vueObject: any) => [
    basicRow(vueObject.$t('cultureReports.totals'), '#5c5655', 'left', 'm1', true),
    basicRow(totalPerRates, '#5c5655', 'center', 'm0', true),
    basicRow(totalPerPopulation, '#5c5655', 'center', 'm0', true)
  ]
  const metaTables = (rows: Array<any>, thisBreack: boolean) => ({
    unbreakable: true,
    table: {
      widths: ['56%', '21%', '18%'],
      color: 'white',
      body: rows
    },
    layout: {
      fillColor: function (rowIndex: number) {
        return (rowIndex === 0) ? '#d1d1d1' : null
      }
    },
    alignment: 'center',
    bold: true,
    margin: thisBreack ? [35, 35, 0, 10] : [35, 5, 0, 10],
    ...(thisBreack ? { pageBreak: 'before' } : {})
  })
  let pageBreak = false
  for (const key in items) {
    const subItems = items[key]
    let totalPerRates = 0
    let totalPerPopulation = 0
    let table = [headerTables(subItems.key, vueObject)]

    if (vueObject.coverItemsName) {
      if (!vueObject.coverItemsName[subItems.key]) {
        vueObject.coverItemsName[subItems.key] = {
          name: vueObject.$t(`reports.demographicCuts.${subItems.key}`).toUpperCase(),
          items: []
        }
      }
    }

    if (subItems.counts.length) {
      for (let index = 0; index < subItems.counts.length; index++) {
        // EDADES y ANTIGUEDADES
        let texto = subItems.counts[index].text
        if (subItems.key !== 'antiquity' && subItems.key !== 'age' &&
          vueObject.listOfDemographics[subItems.key][lang][index].translate
        ) {
          texto = vueObject.listOfDemographics[subItems.key][lang][index].translate.label
        }

        if (subItems.counts[index].countPopulation > 0) {
          if (vueObject.coverItemsName && vueObject.coverItemsName[subItems.key]) {
            vueObject.coverItemsName[subItems.key].items.push(texto)
          }
          table.push([
            basicRow(texto, '#839291', 'left', 'm2', false),
            basicRow(subItems.counts[index].countAnswer, '#839291', 'center', 'm3', false),
            basicRow(subItems.counts[index].countPopulation, '#839291', 'center', 'm2', false)
          ])
          totalPerRates += subItems.counts[index].countAnswer
          totalPerPopulation += subItems.counts[index].countPopulation
          if (checkBreack(countLines)) {
            vueObject.extraPage++
            tables.push(metaTables(table, pageBreak))
            table = [headerTables(subItems.key, vueObject)]
            pageBreak = true
          }
          countLines++
        }
      }
    } else {
      for (let secondKey in subItems.counts) {
        const idIdx = Number(secondKey.replace('id', ''))
        let texto = vueObject.listOfDemographics[subItems.key].find((item: any) => item.id === idIdx)
        if (texto) {
          if (subItems.key === 'headquarter') {
            texto = texto.name
          } else if (texto.translations) {
            texto = (texto.translations[lang === 'es' ? 0 : 1] || {}).label
          } else if (texto.translate) {
            texto = texto.translate.label
          } else {
            texto = ''
          }
        } else {
          texto = subItems.counts[secondKey].text
        }

        if (subItems.counts[secondKey].countPopulation > 0) {
          if (vueObject.coverItemsName && vueObject.coverItemsName[subItems.key]) {
            vueObject.coverItemsName[subItems.key].items.push(texto)
          }
          table.push([
            basicRow(texto, '#839291', 'left', 'm2', false),
            basicRow(subItems.counts[secondKey].countAnswer, '#839291', 'center', 'm3', false),
            basicRow(subItems.counts[secondKey].countPopulation, '#839291', 'center', 'm2', false)
          ])
          totalPerRates += subItems.counts[secondKey].countAnswer
          totalPerPopulation += subItems.counts[secondKey].countPopulation

          if (checkBreack(countLines)) {
            vueObject.extraPage++
            tables.push(metaTables(table, pageBreak))
            table = [headerTables(subItems.key, vueObject)]
            pageBreak = true
          }
          countLines++
        }
      }
    }

    table.push(footerTables(totalPerRates, totalPerPopulation, vueObject))
    tables.push(metaTables(table, pageBreak))
    if (checkBreack(countLines)) {
      vueObject.extraPage++
      pageBreak = true
    } else {
      pageBreak = false
    }
    countLines++
  }
  return tables
}
