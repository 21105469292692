
<template>
  <v-stepper-content :step="step" class="cut-overflow">
    <v-col cols="12" class="pa-3 headline">
      {{ $t('culturePulses.receptors') }}
      <v-divider></v-divider>
      <v-spacer></v-spacer>
    </v-col>
    <v-container>
      <v-row>
        <v-col xs="6" sm="6">
          <x-inputs-autocomplete light persistent-hint
            name="selection_type"
            rules="required"
            :readonly="isEdit || loadingDemographics"
            :items="getSelectType"
            :input="culturePulse.selectionType"
            :label="$t('culturePulses.want_to_send')"
            :append-outer-icon="$t('help.icon')"
            :help-message="$t('help.pulse.create.selection')"
            @updateInput="($event) => culturePulse.selectionType = $event"
          ></x-inputs-autocomplete>
        </v-col>
      </v-row>

      <!------------------------------------->
      <!---------------- ALL ---------------->
      <!------------------------------------->
      <v-row v-if="culturePulse.selectionType === 1">
        <v-col cols="12" class="pa-3 headline">
          {{ $t('culturePulses.total_receptors', {n: `${employeesCount}`}) }}
          <v-spacer></v-spacer>
        </v-col>
      </v-row>

      <!------------------------------------->
      <!---------- DEMOGRAPHIC CUTS --------->
      <!------------------------------------->
      <v-container grid-list-md v-else-if="culturePulse.selectionType == 2">

        <v-row v-if="loadingDemographics">
          <v-col cols="12" class="pt-2 px-0 text-center">
            <v-progress-circular indeterminate
              :width="4"
              :size="54"
              color="primary"
            ></v-progress-circular>
            <p class="caption grey--text text--darken-2">
              {{ $t('culturePulses.please_wait') }}..
            </p>
          </v-col>
        </v-row>

        <v-row v-else align-center justify-center row wrap>

          <!-- Department -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.department || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="departments"
                :input="culturePulse.departmentIds"
                :label="$t('culturePulses.departments')"
                name="departments"
                @updateInput="getDemographicFilteredEmployees($event, 'departmentIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ culturePulse.departmentIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Charge -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.charge || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="charges"
                :input="culturePulse.chargeIds"
                :label="$t('culturePulses.charges')"
                name="charges"
                @updateInput="getDemographicFilteredEmployees($event, 'chargeIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ culturePulse.chargeIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Academic Degree -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.academicDegrees || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="academicDegrees"
                :input="culturePulse.academicDegreeIds"
                :label="$t('culturePulses.academic_degrees')"
                name="academic_degrees"
                @updateInput="getDemographicFilteredEmployees($event, 'academicDegreeIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ culturePulse.academicDegreeIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Job Type -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.jobType || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="jobTypes"
                :input="culturePulse.jobTypeIds"
                :label="$t('culturePulses.job_types')"
                name="job_types"
                @updateInput="getDemographicFilteredEmployees($event, 'jobTypeIds')"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ culturePulse.jobTypeIds.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Age -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.age || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips light clearable
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="getSelectAge"
                :input="culturePulse.rangeAge"
                :label="$t('culturePulses.select_age_range')"
                name="select_age_range"
                @updateInput="getDemographicFilteredEmployees($event, 'rangeAge')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Antiquity -->
          <v-col
            cols="12"
            sm="6"
            v-ripple="{ center: true }"
            v-if="(culturePulse.demographicItems.antiquity || []).length > 0"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips light clearable
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="getSelectAntiquity"
                :input="culturePulse.rangeAntiquity"
                :label="$t('culturePulses.select_antiquity_range')"
                name="select_antiquity_range"
                @updateInput="getDemographicFilteredEmployees($event, 'rangeAntiquity')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Gender -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.gender || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips light clearable
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="genders"
                :input="culturePulse.genderId"
                :label="$t('culturePulses.genders')"
                name="genders"
                @updateInput="getDemographicFilteredEmployees($event, 'genderId')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <v-col cols="12" sm="6"
            v-if="$vuetify.breakpoint.smAndUp"
            v-bind:style="'margin-right:auto'"
          ></v-col>
          <!-- Country -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.country || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="countries"
                :input="culturePulse.countrySelect"
                :label="$t('culturePulses.countries')"
                name="countries"
                @updateInput="getDemographicFilteredEmployees($event, 'countrySelect');"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ culturePulse.countrySelect.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Headquarter -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.headquarter || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light persistent-hint
                :disabled="isEdit || culturePulse.countrySelect.length === 0"
                :filled="culturePulse.status !== 'pending'"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                :readonly="culturePulse.status !== 'pending'"
                :items="headquarters"
                :input="culturePulse.headquarterSelect"
                :label="$t('input.headquarter')"
                name="headquarters"
                @updateInput="getDemographicFilteredEmployees($event, 'headquarterSelect');"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text caption"
                  >(+{{ culturePulse.headquarterSelect.length - 1 }} otros)</span>
                </template>
              </x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Additional Demographic 1 -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.optionalDemo1 || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                name="additionalDemographics1"
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                :readonly="culturePulse.status !== 'pending'"
                :items="additionalDemographics1"
                :input="culturePulse.additionalDemographic1Ids"
                :label="$t('input.optionalDemo1')"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                @updateInput="getDemographicFilteredEmployees($event, 'additionalDemographic1Ids')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Additional Demographic 2 -->
          <v-col
            cols="12"
            sm="6"
            v-if="(culturePulse.demographicItems.optionalDemo2 || []).length > 0"
            v-ripple="{ center: true }"
            v-bind:style="'margin-right:auto'"
          >
            <template>
              <x-inputs-autocomplete outlined chips multiple light
                name="additionalDemographics2"
                :disabled="isEdit"
                :filled="culturePulse.status !== 'pending'"
                :readonly="culturePulse.status !== 'pending'"
                :items="additionalDemographics2"
                :input="culturePulse.additionalDemographic2Ids"
                :label="$t('input.optionalDemo2')"
                v-bind:style="culturePulse.status !== 'pending' ? 'cursor: not-allowed;' : ''"
                @updateInput="getDemographicFilteredEmployees($event, 'additionalDemographic2Ids')"
              ></x-inputs-autocomplete>
            </template>
          </v-col>

          <!-- Total -->
          <v-col cols="12" class="py-0 headline">
            {{ $t('culturePulses.total_receptors', {n: `${totalFilteredEmployees}`}) }}
          </v-col>
        </v-row>
      </v-container>

      <v-row>
        <v-col cols="12" sm="6">
          <v-btn large block
            :disabled="loadingDemographics"
            @click="changeStep(+step - 1)"
          >
            {{ $t('input.back') }}
          </v-btn>
        </v-col>

        <v-col cols="12" sm="6">
          <x-async-btn large block
            class="white--text"
            :disabled="loadingDemographics"
            :action="runValidate"
          >
            {{ $t(nextAction) }}
          </x-async-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-stepper-content>
</template>

<script>

import { mapState } from 'vuex'
import countriesService from '../../../services/countries'
import headquartersService from '../../../services/headquarters'
import academicDegreesService from '../../../services/academic-degrees'

import enterprisesService from '../../../services/enterprises'
import formatItemsGeneral from '../../../utils/form-format-items-list'

import resolver from '../../../utils/resolver'

import EmployeesServices from '../../../services/employees-services'

const formatRes = items => {
  return items.map(item => {
    return {
      value: item.id,
      text: item.name,
      refId: item.countryId
    }
  })
}

export default {
  data () {
    return {
      demographicDataLoaded: false,
      loadingDemographics: false,
      totalFilteredEmployees: 0,
      genders: [],
      min: 0,
      max: 100,
      countries: [],
      headquarters: [],
      jobTypes: [],
      departments: [],
      charges: [],
      academicDegrees: [],
      getSelectAge: [],
      additionalDemographics1: [],
      additionalDemographics2: [],
      originalDemographics: {
        academicDegrees: [],
        additionalDemographics1: [],
        additionalDemographics2: [],
        charges: [],
        countries: [],
        departments: [],
        genders: [],
        jobTypes: []
      },
      demographicFilter: {
        genderId: '',
        departmentIds: [],
        academicDegreeIds: [],
        jobTypeIds: [],
        countrySelect: [],
        headquarterSelect: [],
        chargeIds: [],
        rangeAge: [],
        rangeAntiquity: [],
        additionalDemographic1Ids: [],
        additionalDemographic2Ids: []
      }
    }
  },
  props: {
    culturePulse: Object,
    step: String,
    nextAction: String,
    prevAction: String,
    isEdit: Boolean,
    employeesCount: Number
  },
  computed: {
    ...mapState({
      user: state => state.session.user
    }),
    getSelectAntiquity () {
      return [
        {
          value: [0, 0.5],
          text: this.$t('culturePulses.antiquity_low', { n: 6 })
        },
        {
          value: [0.5, 1],
          text: this.$t('culturePulses.antiquity_range_single', { n: 6, p: 1 })
        },
        {
          value: [1, 3],
          text: this.$t('culturePulses.antiquity_range_one', { n: 1, p: 3 })
        },
        {
          value: [3, 5],
          text: this.$t('culturePulses.antiquity_range', { n: 3, p: 5 })
        },
        {
          value: [5, 10],
          text: this.$t('culturePulses.antiquity_range', { n: 5, p: 10 })
        },
        {
          value: [10, 20],
          text: this.$t('culturePulses.antiquity_range', { n: 10, p: 20 })
        },
        {
          value: [20, 200],
          text: this.$t('culturePulses.antiquity_upper', { n: 20 })
        }
      ]
    },
    getSelectType () {
      return [
        {
          value: 1,
          text: this.$t('culturePulses.to_all_my_organization')
        },
        {
          value: 2,
          text: this.$t('culturePulses.to_demographic_cuts')
        }
      ]
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        this.formatDemoItems()
      }
    },
    'culturePulse.selectionType': {
      handler (val) {
        this.setTotalReceivers(val)
        if (val === 1) {
          this.clearFilters()
        }
        if (val === 2 && !this.demographicDataLoaded) {
          this.loadDemographicData()
        }
      }
    }
  },
  methods: {
    formatDemoItems () {
      this.iniGetSelectAge()

      Object.keys(this.originalDemographics).forEach(dKey => {
        this[dKey] = this.originalDemographics[dKey].filter(x => x.active).map(item => {
          let label = item.translate.label
          if (item.translations) {
            const t = item.translations.find(t => t.lang === this.user.lang)
            if (t) label = t.label
          }

          return {
            value: item.id,
            text: label
          }
        })

        if (dKey === 'countries') {
          this[dKey] = this[dKey].sort((a, b) => a.text.localeCompare(b.text))
        }
      })
    },
    clearFilters () {
      for (const key of Object.keys(this.demographicFilter)) {
        if (key === 'genderId') {
          this.demographicFilter[key] = ''
          this.culturePulse[key] = ''
        } else {
          this.demographicFilter[key] = []
          this.culturePulse[key] = []
        }
      }
    },
    setTotalReceivers (val) {
      switch (val) {
        case 1:
          this.culturePulse.totalReceivers = this.employeesCount
          this.culturePulse.totalPrice = this.culturePulse.price * this.employeesCount
          break
        case 2:
          this.culturePulse.totalReceivers = this.totalFilteredEmployees
          this.culturePulse.totalPrice = this.culturePulse.price * this.totalFilteredEmployees
          break
      }
    },
    iniGetSelectAge () {
      this.getSelectAge = [
        [0, 25],
        [25, 35],
        [35, 45],
        [45, 50],
        [50, 200]
      ].map(range => {
        const option = { value: range, text: '' }
        if (!range[0] || range[1] > 50) {
          option.text = this.$t(
            `culturePulses.${!range[0] ? 'age_low' : 'age_upper'}`,
            { n: range[0] || range[1] }
          )
        } else {
          option.text = this.$t('culturePulses.age_range', {
            n: range[0],
            p: range[1]
          })
        }
        return option
      })
    },
    async getDemographicFilteredEmployees (event, key) {
      this.culturePulse[key] = event
      if (key === 'genderId') {
        this.demographicFilter[key] = event || ''
      } else if (key === 'countrySelect') {
        this.demographicFilter[key] = event || []
        await this.getHeadquarters(event)
      } else {
        this.demographicFilter[key] = event || []
      }

      let flag = false
      for (const i in this.demographicFilter) {
        const filter = this.demographicFilter[i]
        if (typeof filter === 'number' && filter !== '') flag = true
        if (typeof filter === 'object' && filter.length) flag = true
        if (flag) break
      }

      if (flag) {
        this.$emit('parentLoading', true)
        await EmployeesServices.getDemographicParticipants(this.demographicFilter)
          .then((res) => {
            this.totalFilteredEmployees = res.total
          })
          .finally(() => {
            this.$emit('parentLoading', false)
          })
      } else {
        this.totalFilteredEmployees = 0
      }
      this.culturePulse.totalReceivers = this.totalFilteredEmployees
      this.culturePulse.totalPrice = this.culturePulse.price * this.culturePulse.totalReceivers
    },
    getHeadquarters (id) {
      headquartersService.list(id)
        .then(res => {
          this.headquarters = this.headquarters.concat(formatRes(res.items))
          this.headquarters = this.headquarters.filter(item =>
            this.culturePulse.countrySelect.includes(item.refId)
          )
        })
        .catch((this.headquarters = []))
    },
    changeStep (step) {
      this.$emit('changeStep', this.culturePulse, step)
    },
    runValidate () {
      return new Promise((resolve, reject) => {
        if (this.isEdit) this.culturePulse.totalReceivers = this.employeesCount
        if (this.culturePulse.totalReceivers <= 0) {
          this.$store.dispatch(
            'alert/error',
            this.$t('errors.validator/no_receivers')
          )
          return reject(this.$t('errors.validator/no_receivers'))
        }
        this.changeStep(+this.step + 1)
        return resolve()
      })
    },
    checkedDemographic () {
      const listItems = [
        { item: 'departmentIds', name: 'departments' },
        { item: 'chargeIds', name: 'charges' },
        { item: 'academicDegreeIds', name: 'academicDegrees' },
        { item: 'jobTypeIds', name: 'jobTypes' },
        { item: 'genderId', name: 'genders' },
        { item: 'rangeAntiquity', name: 'antiquity' },
        { item: 'rangeAge', name: 'age' },
        { item: 'countrySelect', name: 'countries' },
        { item: 'headquarterSelect', name: 'headquarters' },
        { item: 'additionalDemographic1Ids', name: 'additionalDemographic1' },
        { item: 'additionalDemographic2Ids', name: 'additionalDemographic2' }
      ]
      let k = -1
      while (++k < listItems.length) {
        if (listItems[k].name === 'genders' && this.culturePulse.selectionDetails[listItems[k].item]) {
          this.culturePulse['genderId'] = this.culturePulse.selectionDetails['genderId']
          this.demographicFilter['genders'] = this.culturePulse['genderId']
        } else if (this.culturePulse.selectionDetails[listItems[k].item] &&
            this.culturePulse.selectionDetails[listItems[k].item].length
        ) {
          this.culturePulse[listItems[k].item] = this.culturePulse.selectionDetails[listItems[k].item]
          this.demographicFilter[listItems[k].name] = this.culturePulse[listItems[k].item]
        }
      }
      this.totalFilteredEmployees = this.employeesCount
    },
    forceFlagActive (items) {
      // Additional demographics come already filtered by active status. Add flag to keep consistency
      items.forEach(x => {
        x.active = true
      })
      return items
    },
    loadDemographicData () {
      this.loadingDemographics = true
      this.iniGetSelectAge()
      resolver
        .all({
          enterprise: enterprisesService.getProfile(),
          countries: countriesService.listByEnterprise(),
          academicDegrees: academicDegreesService.listFromSuite()
        })
        .then(res => {
          this.originalDemographics.academicDegrees = res.academicDegrees.items
          this.originalDemographics.countries = res.countries.items
          const demoItems = res.enterprise.demographicItems.filter(
            item =>
              item.data === 'master_reference' ||
              item.code === 'age' ||
              item.code === 'antiquity'
          )

          this.culturePulse.demographicItems = {
            gender: demoItems.filter(item => item.code === 'gender'),
            age: demoItems.filter(item => item.code === 'age'),
            antiquity: demoItems.filter(item => item.code === 'antiquity'),
            country: demoItems.filter(item => item.code === 'country'),
            headquarter: demoItems.filter(item => item.code === 'headquarter'),
            charge: demoItems.filter(item => item.code === 'charge'),
            department: demoItems.filter(item => item.code === 'departments'),
            jobType: demoItems.filter(item => item.code === 'jobTypes'),
            academicDegrees: demoItems.filter(item => item.code === 'academicDegree'),
            optionalDemo1: demoItems.filter(item => item.code === 'optionalDemo1'),
            optionalDemo2: demoItems.filter(item => item.code === 'optionalDemo2')
          }
          this.originalDemographics.genders = res.enterprise.genders
          this.originalDemographics.jobTypes = res.enterprise.jobTypes
          this.originalDemographics.departments = res.enterprise.departments
          this.originalDemographics.charges = res.enterprise.charges
          this.originalDemographics.additionalDemographics1 = this.forceFlagActive(res.enterprise.additionalDemographic1)
          this.originalDemographics.additionalDemographics2 = this.forceFlagActive(res.enterprise.additionalDemographic2)
          this.formatDemoItems()
          this.setTotalReceivers(this.culturePulse.selectionType)
          if (this.isEdit) {
            this.checkedDemographic()
          }
          this.demographicDataLoaded = true
        })
        .finally(() => {
          this.loadingDemographics = false
        })
    }
  }
}
</script>

<style>
  .cut-overflow .v-select__selections {
    overflow: hidden;
  }
</style>
