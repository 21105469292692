
export default {
  add: 'Crear',
  id: 'Id',
  name: 'Nombre',
  status: 'Estado',
  participants: 'Participantes',
  period: 'Periodo',
  action: 'Acciones',
  no_data: 'No hay informacion para mostrar',
  new: 'Nueva Medición Pulsos en Cultura',
  overview: 'Nombre de la encuesta',
  receptors: 'Encuestados',
  date: 'Fecha',
  questions: 'Preguntas',
  revition: 'Revisión',
  poll_name: 'Nombre de la encuesta',
  want_external_name: '¿Desea colocar un nombre externo para los encuestados?',
  external_name: 'Nombre Externo',
  want_to_send: '¿A quién desea enviar esta encuesta?',
  hours: 'Hora de envío',
  hours_valid_until: 'Hora de cierre',
  minutes: 'Minuto',
  date_delivery: 'Fecha de lanzamiento',
  poll_valid_until: 'Encuesta válida hasta',
  time_zone: 'Zona horaria',
  want_send_reminders: '¿Desea enviar recordatorios?',
  reminders: 'Enviar recordatorio',
  reminders_info: 'El recordatorio será enviado a los colaboradores participantes que no hayan completado la medición.',
  want_current_culture_questions: '¿Quieres utilizar el cuestionario de una medición de Cultura Actual realizada previamente?',
  selected: 'Seleccionado(s):',
  questionnaire_table_legend: 'Puedes seleccionar de {min} hasta {max} atributos para tu medición.',
  want_open_question: '¿Desea añadir preguntas adicionales?',
  additional_n: 'pregunta {n}',
  open_question: 'Preguntas adicionales',
  open_question_info: 'Puede indicar preguntas personalizadas adicionales, indicando las posibles respuestas.',
  add_open_question: 'Añadir otra pregunta adicional',
  remove_open_question: 'Eliminar última pregunta adicional',
  min_open_question: 'Debe tener al menos una pregunta abierta disponible',
  max_open_question: 'Solamente puede {n} preguntas abiertas por encuesta',
  insert_question: 'Insertar contenido de la pregunta {n}',
  insert_question_option: 'Insertar opción {o} para pregunta {n}',
  add_question_option: 'Añadir otra opción ',
  remove_question_option: 'Eliminar opción ',
  min_question_option: 'Debe tener al menos {n} opciones disponibles',
  max_question_option: 'Sólo puede indicar {n} opciones para una pregunta abierta',
  option_n: 'opción {n}',
  total_receptors: 'Total de encuestados: {n}',
  porcent_total: 'Porcentaje del total a tomar',
  general_revition: 'Revisión general',
  settings: 'Ajustes',
  cost: 'Costo de la encuesta:',
  poll_paid: 'Encuesta ya ha sido pagada.',
  inter_name: 'Nombre interno',
  send_reminders: 'Envio de recordatorios',
  personalization: 'Personalización de correos electrónicos',
  poll_invitation: 'invitación a la encuesta',
  reminder_mail: 'Correo de recordatorio',
  tk_message: 'Mensaje de agradecimiento',
  message_subject: 'Asunto del mensaje',
  body_message: 'Cuerpo del mensaje',
  message_from_the_company: 'Mensaje por la empresa',
  automatic_message: 'Este mensaje fue automáticamente enviado desde',
  created_culture_pulse: '¡Medición de Pulsos en Cultura creada exitosamente! En breve será redireccionado al resumen de la operación',
  filter_by: 'Filtrar por...',
  look_for: 'Buscar una encuesta',
  engagement_realized: 'Engagement realizados',
  add_engagement: 'Crear Engagement',
  creating: 'Creando',
  pending: 'Pendiente',
  completed: 'Completada',
  cancelled: 'Cancelada',
  progress: 'En progreso',
  in_progress: 'En progreso',
  building: 'En construcción',
  at: 'A las',
  download: 'Descargar',
  aprogress: 'Progreso',
  please_wait: 'Por favor espere mientras se cargan todos los demográficos',
  edit: '¡Encuesta editada exitosamente!',
  tooltip_name: 'Nombre interno de la encuesta',
  tooltip_display_name: 'Nombre de la encuesta para los encuestados',
  summary: 'Resumen de la encuesta',
  to_edit: 'Editar encuesta',
  total_progress: 'Progreso total',
  end_date: 'Fecha cierre',
  poll_completed: 'Encuestas completadas',
  poll_no_completed: 'Encuestas no completadas',
  resend: 'Reenviar encuestas no completadas',
  resend_success: '¡Encuestas reenviadas exitosamente!',
  question_n: '{n} Preguntas',
  no_actions: 'No disponibles',
  all_right: 'Todos los derechos reservados 2020. ©',
  questionnaire_e: 'Cuestionario Cultura',
  questionnaire: 'Cuestionario',
  closed_poll: 'Medicion cerrada exitosamente',
  pulse_report: 'Reporte de Pulso en Cultura',
  associate_required_culture: 'Asociar cultura requerida',
  associate_required_culture_title: 'Asociar Cultura Requerida',
  associate_required_culture_desc: 'Selecciona una medición de Cultura Requerida realizada previamente con el fin de compararla en los reportes de esta medición de Pulsos en Cultura.',
  associate_required_culture_select: 'Seleccione una Cultura Requerida:',
  associate_saved: 'Asociación guardada.',
  no_questionnaries_title: 'No puede continuar en la creación de esta encuesta',
  dimensions: 'Dimensiones',
  results: 'Resultados',
  select_age_range: 'Seleccione un rango de edades',
  to_all_my_organization: 'A toda mi organización',
  to_demographic_cuts: 'A cortes demográficos',
  countries: 'Seleccione algún país.',
  select_antiquity_range: 'Seleccione el rango de antigüedad',
  genders: 'Géneros',
  charges: 'Cargos',
  job_types: 'Tipo de contratación',
  departments: 'Áreas / Departamentos',
  academic_degrees: 'Nivel académico',
  age_low: 'Menos de {n} años',
  age_range: 'De {n} a {p} años',
  age_upper: 'Más de {n} años',
  antiquity_low: 'Menos de {n} meses',
  antiquity_range_single: 'De {n} meses a {p} año',
  antiquity_range_one: 'De {n} año a {p} años',
  antiquity_range: 'De {n} años a {p} años',
  antiquity_upper: 'Más de {n} años',
  // tslint:disable-next-line:max-line-length
  no_questionnaries_body: 'No existen cuestionarios activos para su empresa, por esta razón no puede continuar con la creación de encuestas de este tipo Por favor pongase en contacto con el administrador del sistema para solventar este problema.',
  pdf_explained: 'En este archivo se puden observar las preguntas pertenecientes al cuestionario "{name}" agrupadas por dimensión y atributo.',
  requested: 'Esperadas',
  received: 'Recibidas',
  confirm_create_title: 'Confirme la creación de la medición',
  assessment_cost: 'Costo de la medición',
  balance_after: 'Saldo luego de crear la medición:',
  no_culture_pulse: 'No posee registros de pulsos en cultura',
  go_creation: 'Ir a creación de pulsos en cultura',
  answers_percent: 'Porcentaje de respuestas:',
  resume: 'Resumen de Pulsos en Cultura',
  fileExist: 'Video adjunto',
  deleteFile: 'Eliminar archivo',
  fileExistNote: 'Para sustituir el archivo adjunto no es necesario eliminar el anterior.',
  formatDateAt: 'a las',
  default_body: '<p class="ql-align-justify">Bienvenido(a) al proceso de valoración de Pulsos en Cultura con la herramienta OCC, la cual nos permitirá obtener información valiosa acerca de la Cultura Organizacional de nuestra compañía.</p><p class="ql-align-justify">Información clave sobre esta encuesta:</p><ul><li class="ql-align-justify">La valoración tarda menos de 30 minutos en completarse</li><li class="ql-align-justify">La información entregada será manejada de manera confidencial y será utilizada con fines estadísticos.</li><li class="ql-align-justify">Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado internet.</li><li class="ql-align-justify">Si tiene alguna duda o evidencia algún problema con la encuesta por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org" rel="noopener noreferrer" target="_blank"><u>info@occsolutions.org</u></a></li><li class="ql-align-justify">Recuerde que este link es personal y no se debe compartir.</li></ul><p class="ql-align-justify"><br></p><p class="ql-align-justify">De antemano le agradecemos por su tiempo y sus valiosos aportes en este importante proceso.</p>',
  default_body_r: '<p class="ql-align-justify">Hola de nuevo, te recordamos culminar el proceso de valoración de Pulsos en Cultura con la herramienta OCC, la cual nos permitirá obtener información valiosa acerca de la Cultura Organizacional de nuestra compañía.</p><p class="ql-align-justify">Información clave sobre esta encuesta:</p><ul><li class="ql-align-justify">La valoración tarda menos de 30 minutos en completarse</li><li class="ql-align-justify">La información entregada será manejada de manera confidencial y será utilizada con fines estadísticos.</li><li class="ql-align-justify">Para una mejor experiencia use Google Chrome, recuerde que debe estar conectado internet.</li><li class="ql-align-justify">Si tiene alguna duda o evidencia algún problema con la encuesta por favor no dude en contactarnos en el correo: <a href="mailto:info@occsolutions.org" rel="noopener noreferrer" target="_blank"><u>info@occsolutions.org</u></a></li><li class="ql-align-justify">Recuerde que este link es personal y no se debe compartir.</li></ul><p class="ql-align-justify"><br></p><p class="ql-align-justify">De antemano le agradecemos por su tiempo y sus valiosos aportes en este importante proceso.</p>',
  current_culture: 'Medición de Cultura Actual',
  // Fill
  entry_title: 'Bienvenido a su encuesta de Pulsos en Cultura',
  entry_text: 'Te invitamos a responder la encuesta con la mayor sinceridad posible, seguros de que con tus respuestas construiremos una mejor cultura organizacional. Cuentas con un enlace propio para tu encuesta, recuerda no compartirlo.',
  questions_n: '{n} Preguntas',
  minutes_n: '{n} Minutos',
  answer_poll: 'Responder encuesta',
  totally_disagree: 'Totalmente<br/> en desacuerdo',
  parcially_disagree: 'Parcialmente<br/> en desacuerdo',
  nor_agree_disagree: 'Ni en desacuerdo<br/> ni de acuerdo',
  parcially_agree: 'Parcialmente<br/> de acuerdo',
  totally_agree: 'Totalmente<br/> de acuerdo',
  // tslint:disable-next-line:max-line-length
  poll_text: 'Lea cada una de las afirmaciones presentadas abajo y seleccione la opción que mejor representa su opinión según la siguiente escala: Totalmente en desacuerdo, Parcialmente en desacuerdo, Ni en desacuerdo ni de acuerdo, Parcialmente de acuerdo, Totalmente de acuerdo.',
  // tslint:disable-next-line:max-line-length
  connection_interrupted: 'Conexión interrumpida, continuaremos completando sus respuestas restableciendo la conexión.',
  connection_interrupted_trying: 'Intentando conectar en {seconds} segundos',
  connection_interrupted_out: 'Lo sentimos, por favor verifique su conexión a internet y refresque la página',
  connection_interrupted_connecting: 'Conectando',
  finish_title: 'Muchas gracias por haber completado la encuesta',
  finish_text: 'Su percepción es muy importante para nosotros.',
  congratulations: '¡Felicitaciones, completaste el <span class="headline" style="color: #44C156">{progress}%</span> de la encuesta!'
}
